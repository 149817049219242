import * as React from 'react'
import { Close, MinusRounded } from 'components/icons/index'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import style from './node-reassignment.css'
import { mixpanelActions } from 'utils/mixpanelActions'
import { InputField, Button, Toggler, Icons } from 'plume-ui'
import classNames from 'classnames'
import luStyle from '../account/logo-upload/logo-upload.css'
import { ManageInventoryStore } from './manage-inventory-store'
import * as Papa from 'papaparse'
import { InputMessage } from 'plume-ui/dist/components/InputField/InputField'
import { CSVNodeColumns, NodeCSVColumn, NodeCSVFormat } from 'interfaces/api/portal/i.inventory-api'
import { getDuplicateInArray } from 'helpers/general-helpers'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { NodeCsvUploadPreview } from './node-csv-upload-preview'

const { v4: uuidv4 } = require('uuid')

type NodeRow = {
  POD_SN: string
  PARTNER_ID: string
  GROUP_IDS: string
}

const showNodeCsvUploadPreviewModal = async (
  actionModalStore: ModalLayerStore,
  csvNodesString: string,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <NodeCsvUploadPreview
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        csvNodesString={csvNodesString}
      />,
    )
  })
}

interface NodeImportUpdateProps {
  onClose: () => Promise<void>
  manageInventoryStore: ManageInventoryStore
  action: 'import' | 'update'
}

export const NodeImportUpdate = observer((props: NodeImportUpdateProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const { onClose, manageInventoryStore, action } = props

  const [jobName, setReportName] = React.useState('')
  const [showUpload, setShowUpload] = React.useState(true)
  const [csvError, setCsvError] = React.useState('')
  const [csvString, setCsvString] = React.useState('')

  const { partnerId, odmPortal } = appStore?.authStore?.currentUser?.company
  const { jobs, isLoading, subordinatePartnerIds } = manageInventoryStore

  const isJobNameDuplicate = !!jobs.find(j => j.name === jobName)

  const jobNameInputFieldErrorMessages: Partial<InputMessage>[] = [
    {
      status: 'hint',
      message: t('manageInventory.jobNameMayContain'),
    },
  ]
  if (isJobNameDuplicate) {
    jobNameInputFieldErrorMessages.push({
      status: 'error',
      message: t('manageInventory.duplicateJobNamesNotAllowed'),
    })
  }

  const isJobNameTooLong = jobName?.length > 50
  if (isJobNameTooLong) {
    jobNameInputFieldErrorMessages.push({
      status: 'error',
      message: t('errors.max50CharsAllowed'),
    })
  }

  const isJobNameValid = !jobName || /^[a-zA-Z0-9]([A-Za-z0-9\-]*[a-zA-Z0-9])?$/.test(jobName)
  if (!isJobNameValid) {
    jobNameInputFieldErrorMessages.push({
      status: 'error',
      message: t('manageInventory.jobNameInvalid'),
    })
  }

  function nodeDataReducer(
    prevArray: NodeRow[],
    value: {
      row?: NodeRow
      changes?: Partial<NodeRow>
      remove?: boolean
      add?: boolean
      reset?: boolean
    },
  ) {
    if (value.reset) {
      return [
        {
          POD_SN: '',
          PARTNER_ID: '',
          GROUP_IDS: '',
        },
      ]
    }

    const newArray = [...prevArray]
    if (value.add) {
      newArray.push({ GROUP_IDS: '', POD_SN: '', PARTNER_ID: '' })
      return newArray
    }
    const index = prevArray.findIndex(n => n === value.row)
    if (value.remove) {
      newArray.splice(index, 1)
    } else {
      newArray[index] = { ...prevArray[index], ...value.changes }
    }
    return newArray
  }

  const [nodeRows, dispatchNodeRows] = React.useReducer(nodeDataReducer, [
    {
      POD_SN: '',
      PARTNER_ID: '',
      GROUP_IDS: '',
    },
  ])

  const duplicateInManuallyNodes = getDuplicateInArray(nodeRows.map(n => n.POD_SN))

  let emptyNodeIdsInManualInput = false
  for (const n of nodeRows) {
    if (!n.POD_SN) {
      emptyNodeIdsInManualInput = true
    }
  }

  const manuallyDisallowedPartnerIdNode = !odmPortal
    ? nodeRows?.find(r => r?.PARTNER_ID && !subordinatePartnerIds?.includes(r?.PARTNER_ID))
    : undefined

  const manuallyDisallowedGroupIdNode = !odmPortal
    ? nodeRows?.find(r => r?.GROUP_IDS && !subordinatePartnerIds?.includes(r?.GROUP_IDS))
    : undefined

  React.useEffect(() => {
    mixpanelActions.track('Update Nodes - Modal Open', {
      'Partner Id': partnerId,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCsvChangeHandler = async (event: React.FormEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files[0]
    if (file) {
      const rawCSVString = await file.text()
      const parsedCSV = Papa.parse<NodeCSVFormat>(rawCSVString, {
        header: true,
        delimiter: ',',
        skipEmptyLines: true,
      })

      const wrongColumn = parsedCSV.meta.fields.find(
        (f: NodeCSVColumn) => !CSVNodeColumns.includes(f),
      )
      const noRows = !parsedCSV.data.length
      const maxRows = action === 'import' ? 10000 : 1000
      const tooManyRows = parsedCSV.data.length > maxRows

      const disallowedPartnerIdNode = !odmPortal
        ? parsedCSV.data?.find(
            r => r?.PARTNER_ID && !subordinatePartnerIds?.includes(r?.PARTNER_ID),
          )
        : undefined

      const disallowedGroupIdNode = !odmPortal
        ? parsedCSV.data?.find(r => r?.GROUP_IDS && !subordinatePartnerIds?.includes(r?.GROUP_IDS))
        : undefined

      const duplicatedNodeId = getDuplicateInArray(parsedCSV.data.map(n => n.POD_SN))
      const missingColumnPOD_SN = !parsedCSV.meta.fields.includes('POD_SN')

      if (
        parsedCSV.errors.length ||
        wrongColumn ||
        noRows ||
        tooManyRows ||
        duplicatedNodeId ||
        disallowedPartnerIdNode ||
        disallowedGroupIdNode ||
        missingColumnPOD_SN
      ) {
        setCsvError(
          `${t('manageInventory.invalidCSV')}${
            parsedCSV.errors.length ? `\n${parsedCSV.errors[0].message}` : ''
          }${wrongColumn ? `\n${t('manageInventory.unknownCsvColumn')} ${wrongColumn}` : ''}${
            noRows ? `\n${t('manageInventory.csvMustHaveDataRows')}` : ''
          }${tooManyRows ? `\n${t('manageInventory.csvMaxRowsVariable', { maxRows })}` : ''}
          ${missingColumnPOD_SN ? `\n${t('manageInventory.missingPodSnColumn')}` : ''}${
            duplicatedNodeId
              ? `\n${t('manageInventory.duplicatePodSerialNumber')} ${duplicatedNodeId}`
              : ''
          }${
            disallowedPartnerIdNode
              ? `\n${t('manageInventory.disallowedPartnerId', {
                  partnerId: disallowedPartnerIdNode.PARTNER_ID,
                  nodeId: disallowedPartnerIdNode.POD_SN,
                })}`
              : ''
          }${
            disallowedGroupIdNode
              ? `\n${t('manageInventory.disallowedGroupIdNode', {
                  groupId: disallowedGroupIdNode.GROUP_IDS,
                  nodeId: disallowedGroupIdNode.POD_SN,
                })}`
              : ''
          }`,
        )
        setCsvString('')
        return
      }
      setCsvError('')
      setCsvString(rawCSVString)
    }
  }

  const handleDownloadBlankCSV = () => {
    const fileName = 'blankExampleNodeCheck.csv'
    const headers = CSVNodeColumns.map(l => ({ key: l, display: l }))
    props.manageInventoryStore.exportCSV(headers, [{ POD_SN: 'examplePodSN' }], fileName)
  }

  const handleSwitchTabs = (tabKey: string) => {
    const shouldShowUpload = tabKey === 'uploadDocument'
    if (shouldShowUpload) {
      setCsvString('')
      setCsvError('')
    } else {
      dispatchNodeRows({ reset: true })
    }
    setShowUpload(shouldShowUpload)
  }

  const submitDisabled =
    isJobNameDuplicate ||
    isJobNameTooLong ||
    !isJobNameValid ||
    (showUpload
      ? !csvString || !!csvError
      : !!duplicateInManuallyNodes ||
        !nodeRows?.length ||
        emptyNodeIdsInManualInput ||
        !!manuallyDisallowedPartnerIdNode ||
        !!manuallyDisallowedGroupIdNode)

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (submitDisabled) {
      return
    }
    if (action === 'import') {
      await manageInventoryStore.addImportJob(partnerId, {
        name: jobName || uuidv4(),
        csvString,
      })
    } else if (showUpload && action === 'update') {
      await manageInventoryStore.addUpdateJob(partnerId, {
        name: jobName || uuidv4(),
        csvString,
      })
    } else {
      const manuallyCsvString = Papa.unparse(nodeRows)
      await manageInventoryStore.addUpdateJob(partnerId, {
        name: jobName || uuidv4(),
        csvString: manuallyCsvString,
      })
    }
  }

  const handleShowNodeCsvUploadPreviewModal = () => {
    showNodeCsvUploadPreviewModal(appStore.actionModalStore, csvString)
  }

  return (
    <div className={style.root}>
      <div className={style.subTitle}>
        {t(action === 'update' ? 'manageInventory.updateNodes' : 'manageInventory.importNodesJob')}
      </div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>

      <div className={style.marginBottom10px}>
        {action === 'update'
          ? t('manageInventory.updateNodesDescription')
          : t('manageInventory.importNodesDescription')}
      </div>

      <InputField
        id={'updateJobName'}
        type="text"
        label={t('manageInventory.jobName')}
        value={jobName}
        required={true}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setReportName(e.target.value)
        }}
        messages={jobNameInputFieldErrorMessages}
      />

      {action === 'update' && (
        <div>
          <Toggler
            toggleElements={[
              { key: 'uploadDocument', label: t('manageInventory.uploadDocument') },
              { key: 'inputManually', label: t('manageInventory.inputManually') },
            ]}
            onToggle={s => handleSwitchTabs(s.key)}
          />
          <div className={style.verticalSpace} />
        </div>
      )}

      {showUpload && (
        <div>
          <Button styleVariant="superprimary" onClick={handleDownloadBlankCSV}>
            {t('manageInventory.downloadBlankCSV')}
          </Button>
          <input
            id="updateJobCsvFile"
            className={luStyle.fileInput}
            type="file"
            name="updateJobCsvFile"
            onChange={onCsvChangeHandler}
            accept=".csv"
          />
          <div className={luStyle.fileSelection}>
            <label htmlFor="updateJobCsvFile" className={luStyle.labelLogoTitle}>
              {csvString === '' ? (
                t('manageInventory.uploadCsvHere')
              ) : (
                <>
                  {t('manageInventory.csvUploadedSuccessfully')}{' '}
                  <Icons.CheckedIcon height={18} width={18} />
                </>
              )}
            </label>
            <label htmlFor="updateJobCsvFile" className={luStyle.labelLogoText}>
              {t('logoUpload.selectFileForUpload')}
            </label>
          </div>
          {csvError && <div className={style.serverError}>{csvError}</div>}
          {!!csvString && (
            <>
              <div className={style.separator10px} />
              <Button styleVariant="superprimary" onClick={handleShowNodeCsvUploadPreviewModal}>
                {t('manageInventory.previewData')}
              </Button>
            </>
          )}
        </div>
      )}

      {!showUpload && (
        <>
          <div className={style.nodeInputWrapper}>
            {nodeRows?.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <div>
                    <InputField
                      id={`manualNodeId${index}`}
                      type="text"
                      label={'Node ID'}
                      value={row.POD_SN}
                      required={true}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        dispatchNodeRows({ row, changes: { POD_SN: e.target.value } })
                      }}
                      messages={
                        !!duplicateInManuallyNodes &&
                        !!row.POD_SN &&
                        duplicateInManuallyNodes === row.POD_SN
                          ? [
                              {
                                status: 'error',
                                message: `${t(
                                  'manageInventory.duplicatePodSerialNumber',
                                )}${duplicateInManuallyNodes}`,
                              },
                            ]
                          : !row.POD_SN
                          ? [
                              {
                                status: 'error',
                                message: t('manageInventory.missingNodeId'),
                              },
                            ]
                          : undefined
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      id={`manualPartnerId${index}`}
                      type="text"
                      label={'Partner ID'}
                      value={row.PARTNER_ID}
                      required={true}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        dispatchNodeRows({
                          row,
                          changes: {
                            PARTNER_ID: e.target.value,
                            GROUP_IDS: e.target.value ? e.target.value : '',
                          },
                        })
                      }}
                      messages={
                        !!row.PARTNER_ID &&
                        !odmPortal &&
                        !subordinatePartnerIds.includes(row.PARTNER_ID)
                          ? [
                              {
                                status: 'error',
                                message: t('manageInventory.notInHierarchy', {
                                  id: row.PARTNER_ID,
                                }),
                              },
                            ]
                          : undefined
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      id={`manualGroupId${index}`}
                      type="text"
                      label={'Group IDs'}
                      value={row.GROUP_IDS}
                      required={true}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        dispatchNodeRows({ row, changes: { GROUP_IDS: e.target.value } })
                      }}
                      messages={
                        !!row.GROUP_IDS &&
                        !odmPortal &&
                        !subordinatePartnerIds.includes(row.GROUP_IDS)
                          ? [
                              {
                                status: 'error',
                                message: t('manageInventory.notInHierarchy', {
                                  id: row.GROUP_IDS,
                                }),
                              },
                            ]
                          : undefined
                      }
                    />
                  </div>
                  <div onClick={() => dispatchNodeRows({ row, remove: true })}>
                    <MinusRounded className={classNames(style.override, style.minusSize)} />
                  </div>
                </React.Fragment>
              )
            })}
          </div>

          <div className={style.btnDiv}>
            <Button styleVariant="superprimary" onClick={() => dispatchNodeRows({ add: true })}>
              {t('common.add')}
            </Button>
          </div>
        </>
      )}
      <div className={style.btnDiv}>
        {isLoading && <Loader additionalStyle={{ zIndex: 2000 }} />}
        <Button styleVariant="superprimary" disabled={submitDisabled} onClick={handleFormSubmit}>
          {t('btn.submit')}
        </Button>
      </div>
    </div>
  )
})
