/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer, useMemo, useCallback } from 'react'

import { OdmAccessApi } from 'api/portal/odm-access-api'
import {
  OdmTemporaryAccessListParams,
  OdmTemporaryAccessListResponse,
} from 'interfaces/api/portal/odm-access'

type State = {
  data: OdmTemporaryAccessListResponse | null
  loading: boolean
  error: Error | null
}

// Action types
type Action =
  | { type: 'FETCH_START' }
  | { type: 'FETCH_SUCCESS'; payload: OdmTemporaryAccessListResponse }
  | { type: 'FETCH_ERROR'; payload: Error }

// Reducer function
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_START':
      return { ...state, loading: true, error: null }
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, data: action.payload }
    case 'FETCH_ERROR':
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const useOdmTemporaryAccessList = (
  partnerId?: string,
  params?: OdmTemporaryAccessListParams,
) => {
  const api = useMemo(() => new OdmAccessApi(), [])

  const memoizedParams = useMemo(() => params, [params ? JSON.stringify(params) : undefined])

  const [state, dispatch] = useReducer(reducer, {
    data: null,
    loading: false,
    error: null,
  })

  const fetchData = useCallback(async () => {
    if (!partnerId) return

    dispatch({ type: 'FETCH_START' })

    try {
      const response = await api.getTemporaryAccessList(partnerId, memoizedParams)
      dispatch({ type: 'FETCH_SUCCESS', payload: response })
    } catch (err) {
      dispatch({ type: 'FETCH_ERROR', payload: err as Error })
    }
  }, [partnerId, memoizedParams, api])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { ...state, refetch: fetchData }
}
