import * as React from 'react'
import style from './loader.css'
import classnames from 'classnames'
import { Spinner } from 'plume-ui'
import { SpinnerProps } from 'plume-ui/dist/components/Spinner/Spinner'

interface LoaderProps {
  theme?: SpinnerProps['size']
  withBackground?: boolean
  fit?: boolean
  rootClassName?: string
  loaderClassName?: string
  additionalStyle?: Record<string, any>
}

export const Loader = ({
  fit,
  rootClassName,
  additionalStyle,
  loaderClassName,
  theme,
}: LoaderProps) => (
  <div
    className={classnames(
      rootClassName,
      fit ? style.fitRoot : style.wideRoot,
      !fit && style.rootWithBackground,
    )}
    style={additionalStyle}
  >
    <div className={loaderClassName}>
      <Spinner size={theme} visible />
    </div>
  </div>
)
