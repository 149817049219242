import { alias, list, primitive, custom, serializable } from 'serializr'
import { computed, observable } from 'mobx'
import { IAssignedApplication } from 'interfaces/api/portal/user-api'
import { ICompany } from 'interfaces/api/portal/company-api'
import { findParentCompany, getActiveProductsArray } from 'helpers/company-helpers'
import { AccessRightOption } from 'constants/access-rights'
import { convertSfPicklistStringToArray } from 'helpers/general-helpers'
import { PortalPermission } from 'constants/user/permissions'
import { canAccess } from 'modules/auth/auth-action-permission'

export class User {
  @serializable(alias('firstName'))
  @observable
  public firstName: string

  @serializable
  @observable
  public email: string

  @serializable(alias('lastName'))
  @observable
  public lastName: string

  @observable
  @serializable(alias('sub'))
  id: string

  @computed
  get initials() {
    return this.firstName && this.lastName ? `${this.firstName[0]}${this.lastName[0]}` : 'n/a'
  }

  @serializable
  @observable
  public isSuspended = false

  @serializable(
    alias(
      'partner',
      custom(
        value => !!value,
        jv => !!jv,
      ),
    ),
  )
  @observable
  public isSubscriber: boolean

  @computed
  public get isEmployee() {
    return !this.isSubscriber && this.portalPermissions.includes('plumeAdmin')
  }

  @observable
  public company?: ICompany

  @observable
  public subordinateCompanies?: ICompany[]

  @observable
  public companies?: ICompany[]

  @observable
  public assignedApplications?: IAssignedApplication[]

  @serializable(list(primitive()))
  @observable
  public portalPermissions: PortalPermission[]

  @serializable
  @observable
  public idp_role: string

  public canClone?: boolean

  public sfId?: string

  public cloned?: boolean

  public phoneNumber?: string

  public releaseNotesContact?: boolean

  public serviceDisruptionContact?: boolean

  public securityContact?: boolean

  @computed
  get featureAccessRightsArr(): AccessRightOption[] {
    const companyFeatureAR = convertSfPicklistStringToArray<AccessRightOption>(
      this.company.featureAccessRights,
    )

    const userAccessRights: AccessRightOption[] = []

    if (companyFeatureAR?.includes('USERS') && canAccess('editUsers', this)) {
      userAccessRights.push('USERS')
    }

    if (companyFeatureAR?.includes('HOME_DASHBOARD') && canAccess('viewHome', this)) {
      userAccessRights.push('HOME_DASHBOARD')
    }

    if (companyFeatureAR?.includes('ACCOUNT') && canAccess('viewSettings', this)) {
      userAccessRights.push('ACCOUNT')
    }
    if (companyFeatureAR?.includes('APPLICATIONS') && canAccess('viewApplications', this)) {
      userAccessRights.push('APPLICATIONS')
    }
    return userAccessRights
  }

  @computed
  public get activeProducts() {
    const companyToCheck = !this.isEmployee
      ? findParentCompany(this.companies, this.company)
      : this.company

    const activeProductsArr = getActiveProductsArray(companyToCheck)
    return activeProductsArr
  }

  @computed
  public get applicationsAccessRights() {
    const companyToCheck = !this.isEmployee
      ? findParentCompany(this.companies, this.company)
      : this.company

    return companyToCheck.applicationsAccessRights
  }

  @computed
  public get hasMoreCompanies() {
    return this.companies.length > 1
  }
}
