/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import {
  Button,
  Heading,
  Icons,
  notify,
  PendingContent,
  Popconfirm,
  Spinner,
  TableV2,
  Toggler,
} from 'plume-ui'
import { ColumnType } from 'plume-ui/dist/components/TableV2/types'

import { DashboardLayout } from 'modules/dashboard/common/dashboard-layout'
import { useUpdateCurrentUser } from 'utils/hooks/use-update-current-user'
import { useOdmTemporaryAccessList } from './hooks/useOdmTemporaryAccessList'
import { OdmTemporaryAccess } from 'interfaces/api/portal/odm-access'

import styles from './OdmAccessPage.css'
import AddAuthorizationModal from './AddAuthorizationModal'
import classNames from 'classnames'

import { OdmAccessApi } from 'api/portal/odm-access-api'
import { getFrontlineUrlForCloud } from './utils'
import { SalesforceCloud } from 'constants/salesforce.constants'
import { Redirect } from 'react-router-dom'
import { getODMAccessEnabledClouds } from '../../helpers/environment-variable.helpers'

const OdmAccessPage = observer(() => {
  const [api] = useState(new OdmAccessApi())

  const currentUser = useUpdateCurrentUser()
  const { t } = useTranslation('translation')
  const { data, loading, refetch } = useOdmTemporaryAccessList(currentUser?.company?.partnerId)

  const [selectedStatus, setSelectedStatus] = useState<'active' | 'all' | 'expired'>('active')
  const [addModalOpen, setAddModalOpen] = useState(false)

  const filteredData: typeof data.items = useMemo(() => {
    const rows = [...(data?.items || [])]
      .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map(item => {
        const url = new URL(getFrontlineUrlForCloud(item.cloud as SalesforceCloud))
        url.pathname = `extended-access/customer/${item.customerId}/location/${item.locationId}`
        return {
          ...item,
          accessUrl: url.toString(),
        }
      })

    if (selectedStatus === 'all') return rows
    return rows.filter(item => (selectedStatus === 'active' ? item.isActive : !item.isActive))
  }, [data, selectedStatus, currentUser?.company?.partnerId])

  if (!getODMAccessEnabledClouds()?.includes(currentUser?.company?.cloudEnvironment)) {
    return <Redirect to={`/`} />
  }

  const onReissue = async (id: string) => {
    try {
      await api.reissueTemporaryAccess(currentUser?.company?.partnerId, id)
      refetch()
      notify({
        title: t('common.successful'),
        body: t('odmAccess.reissueSuccessMessage'),
        type: 'success',
      })
    } catch (error) {
      notify({
        title: t('errors.somethingWentWrong'),
        body: typeof error === 'string' ? error : t('errors.somethingWentWrong'),
        type: 'error',
      })
      refetch()
    }
  }

  const onDeactivate = async (id: string) => {
    try {
      await api.revokeTemporaryAccess(currentUser?.company?.partnerId, id)
      refetch()
      notify({
        title: t('common.successful'),
        body: t('odmAccess.deactivateSuccessMessage'),
        type: 'success',
      })
    } catch (error) {
      notify({
        title: t('errors.somethingWentWrong'),
        body: typeof error === 'string' ? error : t('errors.somethingWentWrong'),
        type: 'error',
      })
      refetch()
    }
  }

  const columns: ColumnType<OdmTemporaryAccess & { accessUrl?: string }>[] = useMemo(
    () => [
      {
        key: 'locationId',
        title: t('odmAccess.tableColumns.locationId'),
        dataIndex: 'locationId',
      },
      {
        key: 'cloud',
        title: t('odmAccess.tableColumns.cloud'),
        dataIndex: 'cloud',
      },
      {
        key: 'url',
        title: t('odmAccess.tableColumns.accessRequestUrl'),
        dataIndex: 'accessUrl',
        copyable: true,
      },
      {
        key: 'issuedBy',
        title: t('odmAccess.tableColumns.issuedBy'),
        dataIndex: 'grantedByEmail',
      },
      {
        key: 'email',
        title: t('odmAccess.tableColumns.email'),
        dataIndex: 'issuedToEmail',
      },
      {
        key: 'issuedOn',
        title: t('odmAccess.tableColumns.issuedOn'),
        render: row => moment(row.createdAt).format('YYYY-MM-DD'),
      },
      {
        key: 'status',
        title: t('odmAccess.tableColumns.status'),
        dataIndex: 'isActive',
        render: row => (
          <span
            className={classNames(styles.pill, {
              [styles.pillSuccess]: row.isActive,
              [styles.pillDanger]: !row.isActive,
            })}
          >
            {row.isActive ? t('odmAccess.status.active') : t('odmAccess.status.expired')}
          </span>
        ),
      },
      {
        key: 'timeRemaining',
        title: t('odmAccess.tableColumns.timeRemaining'),
        dataIndex: 'expiresAt',
        render: row => {
          const expiry = moment(row.expiresAt)
          const now = moment()

          if (expiry.isSameOrBefore(now)) {
            return <span className={styles.dangerText}>{t('odmAccess.status.expired')}</span>
          }

          const duration = moment.duration(expiry.diff(now))
          const days = Math.floor(duration.asDays())
          const hours = duration.hours()

          return t('odmAccess.expiresIn', {
            days: days > 0 ? days : 0,
            hours: hours > 0 ? hours : 0,
          })
        },
      },
      {
        title: t('odmAccess.tableColumns.actions'),
        key: 'actions',
        render: (row: OdmTemporaryAccess) => {
          if (row.isActive)
            return (
              //@ts-expect-error - It does accept children but it might be erroring because of different ts versions
              <Popconfirm title={t('common.areYouSure')} onConfirm={() => onDeactivate(row.id)}>
                <Button
                  classes={prev => ({
                    ...prev,
                    container: `${styles.actionButton} ${styles.actionButtonDanger}`,
                  })}
                  styleVariant="tertiary"
                >
                  {t('odmAccess.actions.deactivate')}
                </Button>
              </Popconfirm>
            )
          if (!row.isActive)
            return (
              //@ts-expect-error - It does accept children but it might be erroring because of different ts versions
              <Popconfirm title={t('common.areYouSure')} onConfirm={() => onReissue(row.id)}>
                <Button
                  classes={prev => ({
                    ...prev,
                    container: `${styles.actionButton}`,
                  })}
                  styleVariant="tertiary"
                >
                  {t('odmAccess.actions.reissue')}
                </Button>
              </Popconfirm>
            )
        },
      },
    ],
    [t, currentUser?.company?.partnerId],
  )

  return (
    <DashboardLayout currentUser={currentUser}>
      <div className={styles.root}>
        <Heading level={2}>{t('odmAccess.title')}</Heading>

        <div className={styles.filters}>
          <div className={styles.filter}>
            <Toggler
              value={selectedStatus}
              onToggle={opt => setSelectedStatus(opt.key as typeof selectedStatus)}
              toggleElements={[
                { label: t('common.all'), key: 'all' },
                { label: t('odmAccess.status.active'), key: 'active' },
                { label: t('odmAccess.status.expired'), key: 'expired' },
              ]}
            />
          </div>
          <Button
            onClick={() => setAddModalOpen(true)}
            styleVariant="superprimary"
            icon={<Icons.PlusIcon />}
          >
            {t('odmAccess.addAuthorization')}
          </Button>
        </div>
        <div className={styles.tableContainer}>
          <PendingContent loading={loading} loader={Spinner}>
            <TableV2
              columns={columns}
              data={filteredData}
              noResultsMessage={t('odmAccess.noResults')}
              pagination
              truncateCellContent
            />
          </PendingContent>
        </div>
      </div>

      <AddAuthorizationModal
        partnerId={currentUser?.company?.partnerId}
        onCreated={refetch}
        isOpen={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        availableClouds={currentUser?.company?.allDeployments || []}
      />
    </DashboardLayout>
  )
})

export default OdmAccessPage
