import {
  OdmTemporaryAccessCreateRequest,
  OdmTemporaryAccess,
  OdmTemporaryAccessListParams,
  OdmTemporaryAccessListResponse,
} from 'interfaces/api/portal/odm-access'
import { PortalBaseApi } from './portal-base-api'

export class OdmAccessApi extends PortalBaseApi {
  public async getTemporaryAccessList(partnerId: string, params?: OdmTemporaryAccessListParams) {
    return await this.get<OdmTemporaryAccessListResponse>(
      `/odm-access/${partnerId}/temporary-access`,
      {
        params,
      },
    )
  }

  public async createTemporaryAccess(partnerId: string, data: OdmTemporaryAccessCreateRequest) {
    return await this.post<OdmTemporaryAccess>(`/odm-access/${partnerId}/temporary-access`, data)
  }

  public async reissueTemporaryAccess(partnerId: string, oldTemporaryAccessId: string) {
    return await this.post<OdmTemporaryAccess>(
      `/odm-access/${partnerId}/temporary-access/reissue`,
      {
        oldTemporaryAccessId,
      },
    )
  }

  public async revokeTemporaryAccess(partnerId: string, temporaryAccessId: string) {
    return await this.patch<OdmTemporaryAccess>(
      `/odm-access/${partnerId}/temporary-access/${temporaryAccessId}/revoke`,
    )
  }
}
