import React, { useEffect, useState } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dropdown,
  DropdownItem,
  Icons,
  InputField,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  notify,
  Tooltip,
} from 'plume-ui'

import styles from './AddAuthorizationModal.css'
import { OdmAccessApi } from 'api/portal/odm-access-api'
import { SalesforceCloud } from 'constants/salesforce.constants'

interface AddAuthorizationModalProps {
  isOpen: boolean
  onClose: () => void
  onCreated?: () => void
  partnerId?: string
  availableClouds?: SalesforceCloud[]
}

interface AddAuthorizationFormData {
  locationId: string
  recipient: string
  cloud: SalesforceCloud
}

const AddAuthorizationModal = ({
  isOpen,
  onClose,
  onCreated,
  partnerId,
  availableClouds,
}: AddAuthorizationModalProps) => {
  const { t } = useTranslation('translation')

  const [api] = useState(new OdmAccessApi())
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddAuthorizationFormData>({
    defaultValues: {
      locationId: '',
      recipient: '',
      cloud: availableClouds[0],
    },
    mode: 'onChange',
  })

  const onFormSubmit = async (data: AddAuthorizationFormData) => {
    if (!partnerId) {
      return
    }
    try {
      setLoading(true)
      await api.createTemporaryAccess(partnerId, {
        locationId: data.locationId,
        issuedToEmail: data.recipient,
        cloud: data.cloud,
      })
      notify({
        title: t('odmAccess.modal.authorizationCreated'),
        body: t('odmAccess.modal.authorizationCreatedMessage'),
        type: 'success',
      })
      onCreated?.()
      onClose()
    } catch (error) {
      notify({
        title: 'Error',
        body: typeof error === 'string' ? error : t('odmAccess.modal.authorizationCreateError'),
        type: 'error',
      })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onRequestClose = () => {
    reset()
    onClose()
  }

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableClouds, isOpen])

  return (
    <Modal
      classes={(prev: Record<string, string>) => ({
        ...prev,
        root: `${prev.root} ${styles.modalRoot}`,
      })}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <ModalHeader title={t('odmAccess.modal.title')} />
      <ModalBody>
        <div className={styles.form}>
          <div>
            <Controller
              name="locationId"
              control={control}
              rules={{
                required: t('odmAccess.modal.form.locationIdRequired') as string,
              }}
              render={({ field }) => (
                <InputField
                  {...field}
                  placeholder={t('odmAccess.modal.form.locationIdPlaceholder')}
                  label={t('odmAccess.modal.form.locationId')}
                  messages={
                    errors.locationId
                      ? [
                          {
                            message: errors.locationId?.message,
                            status: 'error',
                          },
                        ]
                      : undefined
                  }
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="recipient"
              control={control}
              rules={{
                required: t('odmAccess.modal.form.recipientRequired') as string,
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t('odmAccess.modal.form.recipientInvalid') as string,
                },
              }}
              render={({ field }) => (
                <InputField
                  {...field}
                  placeholder={t('odmAccess.modal.form.recipientPlaceholder')}
                  label={t('odmAccess.modal.form.recipient')}
                  icon={
                    <Tooltip openInPortal label={t('odmAccess.modal.form.recipientTooltip')}>
                      <Icons.InfoIcon color="white" width={16} height={16} />
                    </Tooltip>
                  }
                  messages={
                    errors.recipient
                      ? [
                          {
                            message: errors.recipient?.message,
                            status: 'error',
                          },
                        ]
                      : undefined
                  }
                />
              )}
            />
          </div>
          <div className={styles.formField}>
            <label htmlFor="cloud-deployment" className={styles.label}>
              {t('odmAccess.modal.form.cloudDeployment')}
            </label>
            <Controller
              name="cloud"
              control={control}
              render={({ field }) => (
                <Dropdown
                  closeOnItemClick
                  classes={prev => ({
                    ...prev,
                    root: `${prev.root} ${styles.dropdown}`,
                  })}
                  openInPortal
                  label={field.value}
                  id="cloud-deployment"
                >
                  {availableClouds.map(cloud => (
                    <DropdownItem
                      selected={field.value === cloud}
                      onClick={() => field.onChange(cloud)}
                      key={cloud}
                    >
                      {cloud}
                    </DropdownItem>
                  ))}
                </Dropdown>
              )}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          styleVariant="tertiary-grey"
          onClick={onRequestClose}
          type="button"
        >
          {t('btn.cancel')}
        </Button>
        <Button
          loading={loading}
          onClick={handleSubmit(onFormSubmit)}
          styleVariant="superprimary"
          type="submit"
        >
          {t('btn.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddAuthorizationModal
