import React, { Component } from 'react'
import { Route, RouteComponentProps, RouteProps, Switch, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { RouterStore } from 'mobx-react-router'
import { ModalLayer } from 'modules/modals/modal-layer'
import * as routes from 'constants/routes'
import { AuthStore } from 'stores/auth-store'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { UiStore } from 'stores/ui-store'
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Login } from 'modules/auth/login'
import { SelectPartner } from './modules/auth/select-partner'
import {
  DashboardApplications,
  DashboardUsers,
  DashboardHome,
  DashboardConfiguration,
} from 'modules/dashboard'
import { AccountSetupRoute } from 'modules/account-setup/account-setup-route'
import { MissingAccessRights } from 'modules/account-setup/missing-access-rights/missing-access-rights'
import { MissingCompany } from 'components/missing-company'
import {
  EnterpriseTermsAndConditions,
  PortalPrivayPolicy,
  PortalTerms,
} from 'modules/account-setup/terms-and-conditions/terms-and-conditions'
import { SelectCompany } from 'modules/account-setup/select-company/select-company'
import { KnowledgeCenter } from 'modules/knowledge-center'
import { initGoogleAnalytics, initGtag } from 'utils/ga/google-analytics'
import { Exception500 } from 'components/exception/500'
import { Exception429 } from 'components/exception/429'
import { Nda } from 'modules/account-setup/nda/nda'
import { AddPasswordSuccess } from 'modules/auth/add-password/add-password-success'
import { AddPassword } from 'modules/auth/add-password/add-password'
import { PasswordResetComplete } from 'modules/auth/password-reset-complete/password-reset-complete'
import { PasswordResetRequest } from 'modules/auth/password-reset-request/password-reset-request'
import { UnlockComplete } from 'modules/auth/unlock-complete'
import { OktaError } from 'components/okta-error'
import { IdpSettings } from 'modules/account-manager/idp-settings/idp-settings'
import { Notifications } from 'plume-ui'
import { setSafariScalingStyle } from 'helpers/general-helpers'
import { ManageTrials } from 'modules/account-setup/manage-trials/manage-trials'
import { ManageInventory } from 'modules/dashboard/manage-inventory/manage-inventory'
import { AppStore } from 'stores/app-store'
import OdmAccessPage from 'modules/odm-access/OdmAccessPage'
import { getODMAccessEnabledClouds } from './helpers/environment-variable.helpers'

interface IAppComponentProps extends RouteComponentProps {
  authStore?: AuthStore
  actionModalStore?: ModalLayerStore
  routerStore?: RouterStore
  uiStore?: UiStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  authStore: appStore.authStore,
  actionModalStore: appStore.actionModalStore,
  routerStore: appStore.routerStore,
  uiStore: appStore.uiStore,
}))
@observer
export class AppComponent extends Component<IAppComponentProps> {
  componentDidMount() {
    setSafariScalingStyle()
    const { handleWindowWidthChange } = this.props.uiStore
    window.addEventListener('resize', handleWindowWidthChange)
    initGoogleAnalytics()
  }

  componentWillUnmount() {
    const { handleWindowWidthChange } = this.props.uiStore
    window.removeEventListener('resize', handleWindowWidthChange)
  }

  UNSAFE_componentWillUpdate() {
    if (location.pathname === this.props.location.pathname) {
      return
    }

    if (this.props.history.action === 'PUSH' || this.props.history.action === 'REPLACE') {
      initGtag(location.pathname)
    }
  }

  restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    this.props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  onAuthRequired = () => {
    this.props.history.push('/login')
  }

  public render() {
    const { authStore, actionModalStore } = this.props

    return (
      <Security
        oktaAuth={authStore.auth}
        restoreOriginalUri={this.restoreOriginalUri}
        onAuthRequired={this.onAuthRequired}
      >
        <Switch>
          <Route path={routes.ADD_PASSWORD_SUCCESS_ROUTE} component={AddPasswordSuccess} />
          <Route
            path={routes.TERMS_AND_CONDITIONS_ROUTE}
            component={EnterpriseTermsAndConditions}
          />
          <Route path={routes.PORTAL_PRIVACY_POLICY_ROUTE} component={PortalPrivayPolicy} />
          <Route path={routes.PORTAL_TERMS_ROUTE} component={PortalTerms} />
          <Route
            path={routes.IMPLICIT_CALLBACK_ROUTE}
            component={(props: RouteProps) => (
              <LoginCallback {...props} errorComponent={OktaError} />
            )}
          />
          <Route exact path={routes.LOGIN_ROUTE} component={Login} />
          <Route exact path={routes.ADD_PASSWORD_ROUTE} component={AddPassword} />
          <Route
            exact
            path={routes.PASSWORD_RESET_COMPLETE_ROUTE}
            component={PasswordResetComplete}
          />
          <Route
            exact
            path={routes.PASSWORD_RESET_REQUEST_ROUTE}
            component={PasswordResetRequest}
          />
          <Route exact path={routes.UNLOCK_COMPLETE_ROUTE} component={UnlockComplete} />
          <SecureRoute exact path={routes.SELECT_PARTNER_ROUTE} component={SelectPartner} />
          <SecureRoute exact path={routes.DASHBOARD_ROOT_ROUTE} component={AccountSetupRoute} />
          <SecureRoute exact path={routes.SELECT_COMPANY_ROUTE} component={SelectCompany} />
          <SecureRoute exact path={routes.MANAGE_PARTNERS_ROUTE} component={ManageTrials} />
          <SecureRoute exact path={routes.MANAGE_INVENTORY_ROUTE} component={ManageInventory} />
          <SecureRoute path={routes.APPLICATIONS_ROUTE} component={DashboardApplications} />
          <SecureRoute path={routes.NDA_ROUTE} component={Nda} />
          <SecureRoute path={routes.HOME_ROUTE} component={DashboardHome} />
          <SecureRoute
            path={`/:partnerId${routes.DASHBOARD_USERS_ROUTE}`}
            component={DashboardUsers}
          />
          <SecureRoute path={routes.MISSING_ACCESS_ROUTE} component={MissingAccessRights} />
          <SecureRoute path={routes.KNOWLEDGE_CENTER_ROUTE} component={KnowledgeCenter} />
          <SecureRoute path={routes.IDP_SETTINGS_ROUTE} component={IdpSettings} />
          <SecureRoute path={routes.ACCOUNT_ROUTE} component={DashboardConfiguration} />
          {!!getODMAccessEnabledClouds()?.length && (
            <SecureRoute exact path={routes.ODM_ACCESS_ROUTE} component={OdmAccessPage} />
          )}
          <SecureRoute
            exact
            path={routes.SIGN_PARTNERSHIP_CONTRACT_ROUTE}
            component={AccountSetupRoute}
          />
          <SecureRoute exact path={routes.EXCEPTION_500_ROUTE} component={Exception500} />
          <SecureRoute exact path={routes.EXCEPTION_429_ROUTE} component={Exception429} />
          <SecureRoute exact path={routes.MISSING_COMPANY_ROUTE} component={MissingCompany} />
        </Switch>
        <ModalLayer modalStore={actionModalStore} />
        <Notifications />
      </Security>
    )
  }
}

export const App = withRouter(AppComponent)
