import * as React from 'react'
import { Close } from 'components/icons/index'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import style from './single-node-inventory-check.css'
import { AccountStore } from 'modules/dashboard/account/account-store'
import { mixpanelActions } from 'utils/mixpanelActions'
import { InputField, Button } from 'plume-ui'
import { InputMessage } from 'plume-ui/dist/components/InputField/InputField'
import JSONPretty from 'react-json-pretty'

const jsonPrettyTheme = require('react-json-pretty/dist/monikai')

interface SNICProps {
  onClose: () => Promise<void>
  accountStore: AccountStore
}

export const SingleNodeInventoryCheck = observer((props: SNICProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const company = appStore?.authStore?.currentUser?.company
  const { onClose } = props

  const { serverError, isLoadingModal, nodeError, nodeInfo } = props.accountStore

  const [nodeId, setNodeId] = React.useState('')
  React.useEffect(() => {
    mixpanelActions.track('Single Node Inventory check - Modal Open', {
      'Partner Id': company?.partnerId,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitDisabled = isLoadingModal || !nodeId

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (submitDisabled) {
      return
    }
    props.accountStore.getNodeInfo(nodeId)
  }

  const nodeIdInputFieldErrorMessages: Partial<InputMessage>[] = []
  if (!nodeId) {
    nodeIdInputFieldErrorMessages.push({ status: 'error', message: t('errors.requiredField') })
  }

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('account.singleNodeInventoryCheck')}</div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>

      <form onSubmit={handleFormSubmit}>
        <div className={style.labelText}>{t('account.singleNodeInventoryCheckDescription')}</div>

        <InputField
          type="text"
          label={t('singleNodeInventoryCheck.nodeId')}
          value={nodeId}
          required={true}
          messages={nodeIdInputFieldErrorMessages}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNodeId(e.target.value)
          }}
        />

        {nodeInfo && <JSONPretty theme={jsonPrettyTheme} data={nodeInfo} />}

        {serverError && <div className={style.serverError}>{serverError}</div>}
        {nodeError && <div className={style.serverError}>{t(`errors.${nodeError}`)}</div>}

        <div className={style.btnDiv}>
          {isLoadingModal && <Loader rootClassName={style.loaderToFront} />}
          <Button styleVariant="superprimary" type="submit" disabled={submitDisabled}>
            {t('btn.check')}
          </Button>
        </div>
      </form>
    </div>
  )
})
