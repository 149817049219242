import * as React from 'react'
import classNames from 'classnames'
import style from './user.css'
import { observer } from 'mobx-react-lite'
import { IUser } from 'interfaces/api/portal/user-api'
import { useTranslation } from 'react-i18next'
import { Chip, Dropdown, DropdownItem, IconButton, Icons, Pagination, Table } from 'plume-ui'
import { DataRow, Heading } from 'plume-ui/dist/components/Table/Table'
import { useState, useEffect } from 'react'
import { useStores } from 'utils/hooks/useStores'
import parseEmail from 'utils/parseEmail'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { DashboardUsersStore } from './user-store'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { ShowPortalCompanies } from './show-portal-companies/show-portal-companies'
import { canUserClone } from 'helpers/user-helpers'
import { mixpanelActions } from 'utils/mixpanelActions'
import { Tooltip } from 'components/tooltip'
import { Loader } from 'components/loader'
import { displayConfirmDeleteModal } from './add-user/add-edit-user'
import { canAccess } from 'modules/auth/auth-action-permission'

export const showAccessiblePCsModal = async (
  actionModalStore: ModalLayerStore,
  usersStore: DashboardUsersStore,
  user: IUser,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      usersStore.setAccessiblePortals([])
      return res(closeButton)
    }
    usersStore.initShowPortals(user.email)
    actionModalStore.addModal(
      <ShowPortalCompanies
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        user={user}
      />,
    )
  })
}

interface UsersTabProps {
  users: IUser[]
  handleEditUser?(user: any): void
  handleUserInfo?(user: any): void
  handleResetPassword?(user: any): void
  isUserItemLoading?: boolean
  handleReactivateUser?(userId: string): Promise<void>
  handleUnlockUser?(userId: string): Promise<void>
  handleCloneUser?(user: any): void
  type: 'active' | 'expired' | 'cloned' | 'invited' | 'locked' | 'suspended' | 'deactivated'
}

type UserTableRow = {
  idx: string
  id: string
  email: string
  initial: string
  username: string
  title: string
  team: string
  releaseNotesContact: string
  serviceNotificationsContact: string
}

type UserTableRowKey = keyof UserTableRow

type TableSortArgument = { fieldName: UserTableRowKey; direction: number }

export const UsersTab = observer((props: UsersTabProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [sortParameters, setSortParameters] = useState<TableSortArgument>({
    fieldName: 'username',
    direction: 1,
  })
  const perPage = 10

  const {
    users,
    handleEditUser,
    handleUserInfo,
    handleReactivateUser,
    handleUnlockUser,
    handleResetPassword,
    handleCloneUser,
    type,
  } = props

  useEffect(() => {
    setPage(1)
  }, [type])

  const newUsers = users
  const currentUser = appStore.authStore.currentUser

  const { clonedUsers, isUserItemLoading, notificationUserColumnsShown } = appStore.usersStore

  const areActive = type === 'active'
  const areExpired = type === 'expired'
  const areCloned = type === 'cloned'

  const canBeReactivated = type === 'invited'
  const canBeUnlocked = type === 'locked'

  const canEditUser = canAccess('editUsers', currentUser)
  const isEmployee = currentUser.isEmployee
  const showResendInvite = canEditUser && canBeReactivated
  const showUnlock = canEditUser && canBeUnlocked
  const showResetPasswordPartial = (areActive || areExpired || areCloned) && canEditUser
  const hasEditOption = showResendInvite || showUnlock || showResetPasswordPartial || canEditUser
  const showAccessiblePortals =
    isEmployee || (!isEmployee && currentUser.company.isChannelPartnerPortalCompany)

  const onChangePage = (pageNum: number) => {
    setPage(pageNum + 1)
  }

  const isFalsey = (str: string) => {
    return str.length === 0 || str === 'false' || str === 'undefined'
  }

  const getUserInitials = (user: IUser) => {
    return user.firstName && user.lastName
      ? `${user.firstName[0]}${user.lastName[0]}`
      : t('users.notAvailableShort')
  }

  const findUserById = (id: string) => {
    return newUsers.filter(user => user.id === id)[0]
  }

  const enabledChip = (
    <div className={style.chipStyle}>
      <Chip type="mini" color="good" selected={true}>
        {t('accountManager.enabled')}
      </Chip>
    </div>
  )

  const disabledChip = (
    <div className={style.chipStyle}>
      <Chip type="mini" color="sore" selected={true}>
        {t('accountManager.disabled')}
      </Chip>
    </div>
  )

  const renderTruncated = (text: string) => {
    if (!text) {
      return ''
    } else {
      return (
        <Tooltip
          overlayClassName={
            text.length > 15
              ? classNames(style.override, style.tooltipVisible)
              : style.showTooltipIfDisplayWidth
          }
          overlay={text}
          placement="topLeft"
          destroyTooltipOnHide={true}
        >
          <div className={style.truncatedCellText}>{`${text.substring(0, 15)}${
            text.length > 15 ? '...' : ''
          }`}</div>
        </Tooltip>
      )
    }
  }

  const handleDeleteUser = (userId: string) => {
    displayConfirmDeleteModal(appStore.actionModalStore, appStore.usersStore, undefined, userId)
  }

  const renderDropDown = (data: DataRow) => {
    const canClone = canUserClone(currentUser)
    const expandDirection = Number(data.idx) % 10 < 6 ? 'bottom' : 'top'

    const handleDotsClick = () => {
      mixpanelActions.track('Users - User Actions', {
        'User Id': data.id,
        'Partner Id': currentUser?.company?.partnerId,
      })
    }
    const dataUser = findUserById(data.id)
    const isIdpUser = !!dataUser?.idp_role
    const isSelf = dataUser?.id === currentUser.id

    return (
      <Dropdown
        closeOnItemClick
        openInPortal
        listPosition="right"
        expandDirection={expandDirection}
        button={
          <IconButton>
            <Icons.DotsVerticalIcon onClick={handleDotsClick} className={style.iconSize} />
          </IconButton>
        }
      >
        {showResendInvite && !isIdpUser && (
          <DropdownItem onClick={() => handleReactivateUser(data.id)}>
            {t('btn.resendInvite')}
          </DropdownItem>
        )}
        {showUnlock && !isIdpUser && (
          <DropdownItem onClick={() => handleUnlockUser(data.id)}>{t('btn.unlock')}</DropdownItem>
        )}
        {showResetPasswordPartial && !isIdpUser && currentUser.id !== data.id && (
          <DropdownItem onClick={() => handleResetPassword(dataUser)}>
            {t('btn.resetPassword')}
          </DropdownItem>
        )}
        {canEditUser && dataUser.editable && (
          <DropdownItem onClick={() => handleEditUser(dataUser)}>{t('btn.edit')}</DropdownItem>
        )}
        {canEditUser && !canBeReactivated && handleUserInfo && (
          <DropdownItem onClick={() => handleUserInfo(dataUser)}>{t('btn.info')}</DropdownItem>
        )}
        {showAccessiblePortals && !isIdpUser && (
          <DropdownItem
            onClick={() =>
              showAccessiblePCsModal(appStore.actionModalStore, appStore.usersStore, dataUser)
            }
          >
            {t('users.showAccessiblePortals')}
          </DropdownItem>
        )}
        {canClone &&
          handleCloneUser &&
          !(
            clonedUsers.filter(
              user => user.email === `portal-users+${parseEmail(data.email)}@plume.com`,
            ).length > 0
          ) &&
          currentUser.id !== data.id && (
            <DropdownItem onClick={() => handleCloneUser(dataUser)}>{t('btn.clone')}</DropdownItem>
          )}
        {isIdpUser && !isSelf && (
          <DropdownItem onClick={() => handleDeleteUser(dataUser.id)}>
            {t('btn.delete')}
          </DropdownItem>
        )}
      </Dropdown>
    )
  }

  const renderInitial = (data: DataRow) => {
    const dataUser = findUserById(data.id)
    const isIdpUser = !!dataUser?.idp_role
    // Activated after being inactive
    const isActivatedAfterTimingOut = dataUser.status === 'PROVISIONED' && !!dataUser.lastLogin
    return (
      <div className={classNames(style.logoCircle)}>
        {isIdpUser ? (
          <Icons.KeySolidIcon className={classNames(style.idpUserLockedIcon, style.override)} />
        ) : isActivatedAfterTimingOut ? (
          <Tooltip
            overlayClassName={style.emailReactivatedTooltip}
            overlay={t('users.reactivatedUserMustSetPassword')}
            placement="top"
            destroyTooltipOnHide={true}
          >
            <Icons.EmailIcon className={classNames(style.reactivatedEmailIcon, style.override)} />
          </Tooltip>
        ) : (
          <span className={style.logoText}>{data.initial}</span>
        )}
      </div>
    )
  }

  const headers: Heading[] = [
    { fieldName: `initial`, render: (data: DataRow) => renderInitial(data) },
    {
      name: t('users.name'),
      fieldName: `username`,
      render: (data: DataRow) => renderTruncated(data.username),
    },
  ]

  if (!notificationUserColumnsShown) {
    headers.push(
      {
        name: t('users.title'),
        fieldName: `title`,
        render: (data: DataRow) => renderTruncated(data.title),
      },
      {
        name: t('users.team'),
        fieldName: `team`,
        sortable: true,
        render: (data: DataRow) => renderTruncated(data.team),
      },
    )
  } else {
    headers.push(
      {
        name: t('users.releaseNotesContact'),
        fieldName: `releaseNotesContact`,
        sortable: true,
        info: `${t('users.releaseNotesContactInfoText')} ${t('users.changeInEditUser')}`,
        render: (data: DataRow) =>
          isFalsey(data.releaseNotesContact) ? disabledChip : enabledChip,
      },
      {
        name: t('users.serviceDisruptionContact'),
        fieldName: `serviceNotificationsContact`,
        sortable: true,
        info: `${t('users.serviceDisruptionContactInfoText')} ${t('users.changeInEditUser')}`,
        render: (data: DataRow) =>
          isFalsey(data.serviceNotificationsContact) ? disabledChip : enabledChip,
      },
      {
        name: t('users.securityContact'),
        fieldName: `securityContact`,
        sortable: true,
        info: `${t('users.securityContactInfoText')} ${t('users.changeInEditUser')}`,
        render: (data: DataRow) => (isFalsey(data.securityContact) ? disabledChip : enabledChip),
      },
    )
  }

  if (hasEditOption) {
    headers.push({ fieldName: '', render: (data: DataRow) => renderDropDown(data) })
  }

  const d = newUsers
    .map((user, index) => ({
      idx: `${index}`,
      id: user.id,
      email: user.email,
      initial: getUserInitials(user),
      username: `${user.firstName} ${user.lastName}`,
      title: user.jobTitle ? user.jobTitle : '',
      team: user.team,
      releaseNotesContact: `${user.releaseNotesContact || false}`,
      serviceNotificationsContact: `${user.serviceDisruptionContact || false}`,
      securityContact: `${user.securityContact || false}`,
    }))
    .sort((d1, d2) =>
      (d1[sortParameters.fieldName]?.toLowerCase() || '') >
      (d2[sortParameters.fieldName]?.toLowerCase() || '')
        ? sortParameters.direction
        : -1 * sortParameters.direction,
    )

  const totalPageCount = Math.ceil(users.length / perPage)
  if (page > totalPageCount) {
    setPage(totalPageCount)
  }

  return (
    <div className={style.userListWrapper}>
      <div className={style.userItems}>
        <Table
          headerRow={headers}
          dataRows={d.slice((page - 1) * perPage, page * perPage)}
          externalSort={true}
          onSortChange={sp => {
            setSortParameters(sp as TableSortArgument)
            setPage(1)
          }}
          classes={styles => {
            return {
              ...styles,
              root: classNames(styles.root, style.override, style.tableSize),
              heading: classNames(styles.heading, style.override, style.userHeading),
              cell: classNames(styles.cell, style.override, style.userTableCell),
              headerRow: classNames(styles.headerRow),
            }
          }}
          truncateCellContent
        />
        {isUserItemLoading && <Loader fit />}
      </div>

      <div className={style.pagination}>
        <Pagination
          expandDirection="top"
          currentPage={page - 1}
          onPageSelect={onChangePage}
          totalPageCount={totalPageCount}
        />
      </div>
    </div>
  )
})
