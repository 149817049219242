import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import { History } from 'history'
import { UiStore } from 'stores/ui-store'

interface Options {
  history: History
  uiStore: UiStore
}

interface ErrorResponse {
  statusCode: number
  statusText: string
  message: string
}

export class PortalBaseApi {
  private static sharedAxiosInstance = axios.create()
  private static history: History
  private static uiStore: UiStore

  public static setHistoryAndUiStore(options?: Options) {
    if (options.history) {
      this.history = options.history
    }
    if (options.uiStore) {
      this.uiStore = options.uiStore
    }
  }

  public static setDefaultAuthorizationHeader(token?: string) {
    if (token) {
      this.sharedAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
      delete this.sharedAxiosInstance.defaults.headers.common['Authorization']
    }
  }

  public static setDefaultPartnerGroupHeader(group?: string) {
    if (group) {
      this.sharedAxiosInstance.defaults.headers.common['X-Partner-Group'] = group
    } else {
      delete this.sharedAxiosInstance.defaults.headers.common['X-Partner-Group']
    }
  }

  protected get uiStore() {
    return PortalBaseApi.uiStore
  }

  protected get history() {
    return PortalBaseApi.history
  }

  protected getBaseUrl(): string {
    return process.env.PORTAL_API_BASE_URL
  }

  protected get axiosInstance() {
    return PortalBaseApi.sharedAxiosInstance
  }

  protected async get<T>(path: string, config?: AxiosRequestConfig) {
    try {
      const response = await this.axiosInstance.get<T>(this.getBaseUrl() + path, config)

      return response.data
    } catch (e) {
      this.error(e)
    }
  }

  protected async post<T>(path: string, data?: any, config?: AxiosRequestConfig) {
    try {
      const response = await this.axiosInstance.post<T>(this.getBaseUrl() + path, data, config)

      return response.data
    } catch (e) {
      this.error(e)
    }
  }

  protected async put<T>(path: string, data?: any, config?: AxiosRequestConfig) {
    try {
      const response = await this.axiosInstance.put<T>(this.getBaseUrl() + path, data, config)

      return response.data
    } catch (e) {
      this.error(e)
    }
  }

  protected async patch<T>(path: string, data?: any, config?: AxiosRequestConfig) {
    try {
      const response = await this.axiosInstance.patch<T>(this.getBaseUrl() + path, data, config)

      return response.data
    } catch (e) {
      this.error(e)
    }
  }

  protected async delete<T = void>(path: string, config?: AxiosRequestConfig) {
    try {
      const response = await this.axiosInstance.delete<T>(this.getBaseUrl() + path, config)

      return response.data
    } catch (e) {
      this.error(e)
    }
  }

  protected error(e: AxiosError<ErrorResponse>) {
    const is500 = e.response.status === 500 || e.response.status === 503
    const tooManyRequests = e.response.status === 429

    const isListUsers = e.response.config.url.includes('list-users')
    const isMobileConfig = e.response.config.url.includes('mobile-config')
    const isAppLogo = e.response.config.url.includes('app-logo')
    const isAccountManager = window.location.pathname.includes('account-manager')

    if (tooManyRequests) {
      window.location.href = `${window.location.origin}/exception/429`
    }

    if (e.response.data.statusCode === 401) {
      window.location.href = `${window.location.origin}/login`
    }

    if (isAccountManager) {
    } else if (is500 && !isListUsers && is500 && !isMobileConfig && is500 && !isAppLogo) {
      const data = {
        endpoint: e.request.responseURL,
        message: e.response.data.message,
      }

      this.uiStore.setException(data)
      this.history.replace(`/exception/500?statusCode=${e.response.status}`)
    }

    throw e.response.data.statusText || e.response.data.message
  }
}
